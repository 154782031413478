import React from 'react';

import { navigate } from 'gatsby';

const PilotPage = () => {
  React.useEffect(() => {
    navigate('/credit-score');
  }, []);

  return <></>;
};

export default PilotPage;
